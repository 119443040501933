<template :key="selected_property._id">
    <div class="container-fluid" >
        <div class="row">
            <div class="col-md-12 ">
                <h4 class="title-in-h">{{$t('property.data.header')}}</h4>
                <breadcrumb >
                    <breadcrumb-item  v-for="breadcrumb in breadcrumbs" v-if="breadcrumb.type!=='active'">
                        <router-link :to="{path:breadcrumb.path}" class="color-blue-var">{{breadcrumb.name}}</router-link>
                    </breadcrumb-item>
                    <breadcrumb-item active v-else>
                        {{breadcrumb.name}}
                    </breadcrumb-item>
                </breadcrumb>
            </div>
        </div>



        <div class="row" ref="elementResume" >
            <div class="col-12">
                <card class="mt-2" v-loading="screenLoading"
                      element-loading-lock="true"
                      :element-loading-text="screenText"
                      element-loading-spinner="el-icon-loading">
                    <div>
                        <div class="col-sm-12">
                            <div class="box-info-resume" >
                                <div class="box-top">
                                    <h6 class="color-primary">{{property.es.name}}</h6>
                                    <img :src="selected_property.logo" class="img-logo">
                                    <el-rate
                                            v-model="property.category"
                                            disabled
                                            text-color="#ff9900">
                                    </el-rate>
                                    <label class="mt-2">
                                        {{$t('form.street')+' '+property.street+' '+property.ext_number+' '+property.int_number+' Col. '+property.suburb+', '+property.city+', '+property.state+', '+property.zc+', '+property.country}}
                                    </label>

                                    <label>
                                        {{$t('form.phone_property')}}:
                                        <span>{{property.phone}}</span>
                                    </label>
                                    <label>
                                        {{$t('form.website')}}:
                                        <span><a :href="property.es.url" target="_blank" class="color-blue-var">{{property.es.url}}</a> </span>
                                    </label>

                                    <label v-if="property.brand!==''">
                                        {{$t('form.mark')}}:
                                        <span>{{property.brand}}</span>
                                    </label>
                                </div>
                                <div class="box-top">

                                    <h6 class="color-primary"> {{$t('form.description')}}</h6>
                                    <label>
                                         <span>{{property.es.description}}</span>
                                    </label>

                                </div>
                            </div>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>

</template>
<script>
    import {Breadcrumb, BreadcrumbItem} from 'src/components/index'
    import {Rate } from 'element-ui'
    //import users from 'src/pages/Dashboard/Tables/users'
    import PropertyService from '../../../js/services/PropertyService';
    import EventBus from "../../../js/helpers/EventBus";
    import { vueTopprogress } from 'vue-top-progress'

    const propertyService = new PropertyService();
    import { mapGetters } from "vuex";
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'
    export default {
        computed: {
          ...mapGetters(["isLoggedIn", "currentUser", "selected_property","properties"]),
        },
        components: {
            Breadcrumb,
            [Rate.name]: Rate,
            BreadcrumbItem,
            vueTopprogress
          },
        
        data() {
            return {
                screenLoading: false,
                screenText: '',
                breadcrumbs:[
                    {
                        name: this.$t('property.index.home'),
                        path: '/admin/properties',
                        type: '',
                    },
                    {
                        name: this.$t('property.index.property'),
                        path: '/admin/properties',
                        type: '',
                    },

                    {
                        name: this.$t('property.data.header'),
                        path: '',
                        type: 'active',
                    }
                ],
                info_page:{
                    property_id: {},
                    tab_lang: 'es',
                    langs:[{
                        name: this.$t('locale_es'),
                        locale: 'es'
                    },{
                        name: this.$t('locale_en'),
                        locale: 'en'
                    },]
                },
                property: {
                    _id: '',
                    category: 0,
                    phone: '',
                    street: '',
                    logo: '',
                    ext_number: '',
                    int_number: '',
                    suburb: '',
                    brand: '',
                    zc: '',
                    city: '',
                    state: '',
                    country: '',
                    url: '',
                    es:{
                        name:'',
                        description:'',
                        url: ''
                    },
                    en:{
                        name:'',
                        description:'',
                        url: ''
                    }
                },
                section: 'PROPERTY'

            }
        },
        created(){
            this.chargerItems(this.selected_property._id);
            this.$store.dispatch('CHANGE_SECTION',this.section);
        },
        methods: {
            changeProperty(){
                EventBus.$on('CHANGE_PROPERTY', (property_id) => {
                    if(this.$route.name==='ResumeProperty') {
                        this.chargerItems(this.selected_property._id);
                    }

                })
            },

            chargerItems(property_id = null){

                this.initLoading();
                this.info_page.property_id = property_id;
                propertyService.getPropertyId(property_id).then(response => {
                    let data = response.data.data.property;
                    this.property._id = data._id;
                    this.property.logo= data.logo;
                    this.property.category= data.category;
                    this.property.phone= data.phone;
                    this.property.street= data.street;
                    this.property.ext_number= data.ext_number;
                    this.property.int_number= data.int_number;
                    this.property.suburb= data.suburb;
                    this.property.zc=data.zc;
                    this.property.city= data.city;
                    this.property.state=data.state;
                    this.property.country=data.country;
                    this.property.brand=data.brand;
                    this.property.es.name = data.name;
                    this.property.es.description = data.description;
                    this.property.es.url = data.url;
                    this.closeLoading();
                });
            },

            initLoading(text = 'Cargando...') {
                this.screenLoading = true;
                this.screenText = text;
            },
            closeLoading(){
                this.screenLoading = false;
            }
        },
        mounted() {
            this.changeProperty()
        }
    }
</script>
<style lang="scss">

    .box-info-resume{
        border: 0;
        .img-logo{
            max-width: 300px;
            max-height: 200px;
            position: absolute;
            right: 5%;
            top: 5%;
        }
        .box-top {
            padding: 25px;
            border: 2px solid #eeeeee;
            margin-bottom: 15px;
        }
        label{
            color: #000;
            width: 100%;
            display: inline-block;
            margin-bottom: 15px;
            font-size: 16px;
            text-transform: unset;
            span{
                font-weight: 400;
                margin-left: 5px;
            }
            span.icon-cont{
                margin-left: 0;
            }

        }
        h6{
            margin-bottom: 15px;
            font-size: 18px;
            text-transform: unset;
        }
    }
</style>
